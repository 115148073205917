
////////////////////////////
// INCLUDE VENDOR
////////////////////////////

@import "fontawesome/font-awesome.less";
@import "better-new-design.less";

.video__img{
    background-color: #000;
}


.video-player__aside .table.table-compact td{
    width:auto;
    text-align: left;
}

.video-player__aside .table.table-compact th{
    max-width: 115px !important;
    width: 115px;
}

#dropdownsubtarget li a {
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    font-size: 1rem;
    background-color: #fff;
    color: #1b4098;
    display: block;
    font-weight: bold;
    padding: 10px 15px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
}

#dropdownsubtargetdiv {
    background-color:#fff;
    //height:200px;
    position:absolute;
    z-index:100;
}

#dropdownsubtarget li a:hover {
    background-color: #f7f7f7;
}

#dropdownsubtarget {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.dropdown-items.open > ul {
    background-color: #fff;
}
.logo{
    background-color: #fff;
    padding: 0px 82px 0 16px;
    height: 90px;
    z-index: 2;
    line-height: 69px;
    text-align: center;
    display:table;
    .img-responsive{
        height: 90px;
        z-index: 3;
        position: relative;
        line-height: 90px;
        overflow: hidden;
        display:block;
        text-align: center;
         vertical-align: middle !important;
        iframe, div{
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle !important;
        }

    }
    &:before {
        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 94px 66px;
        content: " ";
        height: 0;
        left: -65px;
        position: absolute;
        top: -98px;
        width: 0;
        z-index: 0;
}
    &:after {
        border-color: transparent transparent #ff2a40;
        border-style: solid;
        border-width: 92px 66px;
        content: " ";
        height: 0;
        position: absolute;
        right: -64px;
        top: -94px;
        width: 0;
        z-index: 0;
    }
}
.logo.banner-logo .img-responsive{
    display: table-cell;
    text-align: left;
}
.logo .img-responsive iframe div, .logo .img-responsive iframe div img{
     max-width: 100%;
}
.logo.banner-logo .img-responsive{
   // max-width: 40%;
    margin:3px 3% 0 7%;
    height: auto;
    max-height: 76px;
    text-align: right;
}
#ad_wrapper{
    max-width: 100%;
}
.l-main .banner{
    overflow: hidden;
    width:100%;
    z-index:19;
}
.logo.banner-logo .logo-federation{
    margin:0px 7% 0 3%;
    text-align:left;
}
.logo .logo-federation img{
    max-width: 100%;
    height: auto;
    max-height: 76px;
}
.publisher{
    position: relative;
    z-index: 3;
}

.tabs .open a{
    color: #ff2a40;
}
.tabs .dropdown-toggle:hover:after, .tabs a:hover:after, .tabs .open a:after{
    background-color: #ff2a40;
    right: 0;
}
.tabs a:hover .ico--arrow.is-black.is-small ,.tabs .open .ico--arrow.is-black.is-small,.tabs .is-active .ico--arrow.is-black.is-small {
    background-position: -9px 0;
}
.ico--arrow.is-blue.is-small{
    background-position: -27px 0;
}
.dropdown-items li.is-active a{
    color: #ff2a40;
}
.tabs .open a{
    color:#ff2a40;
}
.is-active .ico--arrow.is-black.is-small , .open .ico--arrow.is-black.is-small {
    background-position: -9px 0;
}
/**btn previous***///
.hero__prev-slide.btn {
  background-color: rgba(255, 42, 64, 0.5);
  border-radius: 0;
  bottom: 0;
  height: 100px;
  position: absolute;
  left: 0;
  text-align: center;
  transition: background-color 0.28s ease;
  width: 44px;
  z-index: 20;
  &:hover{
    background-color: rgba(255, 42, 64, 0.8);
    }
}
/* line 221, /www/lekkerduidelijk/eurovisionsports/source/assets/css/module/hero.less */
.cssanimations .hero__prev-slide {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: .51s;
  animation-delay: .51s;
   .ico--arrow{
        transform: rotate(180deg);
    }
}
.scrbbl-embed iframe{
    width: 100% !important;
}
#ids-banner{
    color:#fff;
}
/****responsive ***/
.video__still{
    width: 100%;
}
.video__imgbody {
    min-height: 152px;
}

#ebu-level2-div-video-page .pager ~ .pager {
    display: none;
}

body {
    .hero--slider {
        z-index: 20;
        .hero__item {
            display: flex;
            display: -ms-flexbox;
            align-items: center;
            -ms-flex-align: center;

          &.hero__item__live {
                .hero__item__copy {
                    padding-top: 50px;
                }

                &:after {
                    background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
                }
            }

            &.hero__item__coming {
                align-items: flex-end;
                -ms-flex-align: end;
            }
        }
    }

    .image_16_9 {
        .aspect-ratio(16, 9);

        .content {
            .block__item__image, .video__img {
                &, &>img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .sixteen-nine > .content > .block__item__image,
        .sixteen-nine > .content > .block__item__image > img {
          width: 100%;
          height: 100%;
        }
    }
}

@media (max-width: 1200px){
    .scrbbl-embed iframe{
        height: 555px !important;
    }
    .logo.banner-logo .logo-federation{
        max-width: 87%;
    }
    .l-header .container{
        position: relative;
        padding-bottom: 10px;
    }
    .branding a{
        font-size: 30px;
    }
    #ebu-ads img{
        max-width: 100%;
    }
}

@media (max-width: 991px){

    .branding a{
        font-size: 36px;
    }
    .withlogo .branding,.logo {
        padding-right: 16px;
        width: 100%;
        float: none;
    }
    .withlogo .publisher{
        position: absolute;
        right:0;
        top:0;
        width: 100%;
        float: none;
        text-align: right;
    }

    .logo:before,.logo:after{
        display: none;
    }
    .l-header{
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hero--slider .hero__item.hero__item__placeholder {
        min-height: 450px !important;
    }
}

@media (max-width: 767px){
    .l-header .container{
    width: 100% !important;
    }
    .logo.col-md-6{
        clear:both;
        margin-bottom: 10px;
        padding: 0 20px 0 20px;
        &:after{
            display: none;
        }
        &:before {
        display: none;
        }
    }
    .slick-track .col-md-7{
        float:none !important;
    }
    .container {
        width: 100%;
    }

    .hero--slider {
        .hero__item {
            padding: 0 0 70px 0;

            &.hero__item__placeholder {
                min-height: 350px !important;
            }

            &.hero__item__live .hero__item__copy {
                padding-top:20px !important;
            }
        }

        .hero__next-slide.btn, .hero__prev-slide.btn {
            height: 50px;
        }
    }
}
@media (max-width: 550px){
    .scrbbl-embed iframe{
        height: 1078px !important;
    }
    .logo.banner-logo .logo-federation,.logo.banner-logo .img-responsive, .logo{
        display: block;
        clear: both;
        text-align: center;
        margin:0px auto;
        float:none;
    }
    .logo.col-md-6{
        height:auto;
        padding-top:10px;
        padding-bottom:10px;
    }
}
.tabs .dropdown-toggle{
    outline: none;
}

.aspect-ratio(@width, @height) {
    @ar: (@height / @width) * 100%;

    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: @ar;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.search{
    float:right;
    position: relative;
    top:30px;
}
.search span{
    display: inline-block;
    width: 34px;
    background: #ff2a40;
    text-align: center;
    line-height: 20px;
    height:34px;
    float:left;
}

.search span i{
    width:20px;
    height: 20px;
    display: inline-block;
    margin-top:7px;
}
.search div{
    position:absolute;
}

.search input{
    float:left;
    color: #888888;
    font-size: 16px;
    height: 34px;
    border:1px solid #e6e6e6;
    font-weight:normal;
    padding-left: 10px;
    outline: none;
}
.notfound{
    margin-top: 0;
    display: block;
    padding-top: 29px;
}
.hero__title {
    font-size: 35px !important;
}
@media (min-width: 768px){
    .hero__title {
        font-size: 40px !important;
    }
}

//fix for jwplayer bug on chrome
//don't know how but it works
.jw-display-icon-container.jw-reset {
   pointer-events: auto !important;
}

.responsive-16by9 {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}
